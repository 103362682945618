<template>
  <section>
    <b-row>
      <b-col cols="4">
        <b-form-group>
          <label for="patientOrGuide">Paciente/Guia</label>
          <div class="search-container">
            <Search class="icon" />
            <b-form-input
              id="patientOrGuide"
              v-model="filters.patientOrGuide"
              debounce="500"
              placeholder="Buscar paciente ou guia"
            />
          </div>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <label for="healthPlan">Convênio</label>
          <HealthPlanFilter :selectHealthPlan="handleSelectedHealthPlans" :diferentTop="true" />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="GuideType">Tipo de guia</label>
          <Autocomplete
            id="GuideType"
            v-model="filters.guideType"
            :options="guideTypes"
            placeholder="Pesquisar"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="situation">Situação</label>
          <multiselect
            id="situation"
            v-model="filters.situation"
            :options="situations"
            class="with-border"
            :show-labels="false"
            placeholder="Pesquisar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <b-form-group>
          <label for="invoice">Fatura</label>
          <b-input
            id="invoice"
            v-model="filters.invoice"
            debounce="500"
            placeholder="Selecionar"
          />
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <label for="lotNumber">Nº Lote</label>
          <b-input
            id="lotNumber"
            debounce="500"
            v-model="filters.lotNumber"
            placeholder="Pesquisar"
          />
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <label for="procedure">Procedimento</label>
          <DatabaseCheckboxSelect
            id="procedure"
            type="procedures"
            label="Todos os procedimentos"
            v-on:onChange="handleSelectedProcedures"
            placeholder="Pesquisar"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <b-form-group>
          <label for="professional">Executor(a) da Guia</label>
          <multiselect
            class="with-border"
            :options="professionals"
            :value="professional"
            label="label"
            @search-change="getProfessionalsAuditDebounce"
            @select="selectProfessional"
            :show-labels="false"
            id="professional"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions">
              Digite para pesquisar um profissional
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <label for="action_description">Descrição da ação</label>
          <multiselect
            class="with-border"
            v-model="filters.actionDescription"
            :options="actions"
            :show-labels="false"
            id="action_description"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions" v-if="!actions.length">
              Preencha um profissional
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col cols="4" class="filter-div">
        <b-form-group>
          <div class="label-container">
            <label for="">Período</label>
            <b-form-radio v-model="filters.period.type" value="create">
              Criação
            </b-form-radio>
            <b-form-radio v-model="filters.period.type" value="close">
              Fechamento
            </b-form-radio>
          </div>
          <div>
            <Periods @select="handlePeriod" />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses'
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Search: () => import('@/assets/icons/search.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Periods: () => import('@/components/General/Periods'),
    Autocomplete: () => import('@/components/Autocomplete'),
    DatabaseCheckboxSelect: () => import('@/components/DatabaseCheckboxSelect.vue'),
    HealthPlanFilter: () => import('./HealthPlanFilter'),
  },
  created() {
    this.getProfessionalsAuditDebounce = debounce(
      this.getProfessionalsAudit,
      500
    )
  },
  data() {
    return {
      getProfessionalsAuditDebounce: Function,
      clinic: getCurrentClinic(),
      professional: null,
      action: null,
      filters: {
        patientOrGuide: null,
        healthPlan: null,
        guideType: null,
        situation: null,
        invoice: null,
        lotNumber: null,
        procedures: null,
        professional: null,
        actionDescription: null,
        period: {
          start: null,
          end: null,
          type: 'create'
        }
      },
      guideTypes: [
        {
          label: 'Consulta',
          value: 'TissGuideConsultation'
        },
        {
          label: 'SP/SADT',
          value: 'TissGuideSpSadt'
        },
        {
          label: 'Honorários',
          value: 'TissGuideFee'
        },
      ],
      situations: [
        TissGuide.GUIDE_SITUATION_IN_PREPARATION,
        TissGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION,
        TissGuide.GUIDE_SITUATION_AUTHORIZED,
        TissGuide.GUIDE_SITUATION_DENIED,
        TissGuide.GUIDE_SITUATION_CANCELED,
        TissGuide.GUIDE_SITUATION_SENT,
        TissGuide.GUIDE_SITUATION_OPEN,
        TissGuide.GUIDE_SITUATION_PAYED,
        TissGuide.GUIDE_SITUATION_PARTIALLY_PAID,
        TissGuide.GUIDE_SITUATION_GLOSSED
      ],
      professionals: []
    }
  },
  computed: {
    actions() {
      if (!this.professional || !this.professional.value) return []
      return [
        'Selecionar',
        'Criou a guia',
        'Alterou o status da guia',
        'Conferiu a guia',
        'Anexou um arquivo',
        'Enviou o lote',
        'Fechou o lote',
        'Lote em recurso'
      ]
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedProcedures(newValue) {
      this.filters.procedures = newValue
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    },
    selectProfessional(professional) {
      this.professional = professional
      this.filters.professional = professional.label
      if (!professional.value) {
        this.filters.actionDescription = null
      }
    },
    async getProfessionalsAudit(query) {
      if (query.length > 2) {
        try {
          const response = await this.api.getProfessionalAudit(
            this.clinic.id,
            query
          )
          const professionals = response.data.map(audits => {
            return audits.person
          })
          this.professionals = professionals.map(professional => {
            return {
              label: professional.name,
              value: professional.id
            }
          })
          this.professionals.unshift({
            label: 'Selecionar',
            value: null
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.label-container {
  display: flex;
  flex-direction: row;

  label {
    margin-right: 20px;
  }

  div {
    font-size: 15px !important;
    margin-right: 15px;
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  position: relative;
  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    height: 100%;
    margin-left: 10px;
  }

  input {
    padding-left: 40px !important;
  }
}
</style>
